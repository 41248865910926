import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { UseQueryReturnType } from 'api/common/types'
import { useFetchGeographyApi } from 'api/masterData/queries/useFetchGeographyApi'
import { Flex } from 'components/common/flex/Flex'
import { FormInputAutocomplete } from 'components/form/formAutocomplete/FormInputAutocomplete'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { useForm } from 'hooks/form/useForm'
import { EntityDetailsCards } from 'pages/components/entityDetailsCards/EntityDetailsCards'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { ActionButtons } from 'pages/geographies/upsert/components/ActionButtons'
import { GeographyMarketsByTypes } from 'pages/geographies/upsert/components/GeographyMarketsByTypes'
import { defaultValues, useGeographyValidationScheme, convertToForm } from 'pages/geographies/upsert/utils'
import { Geography } from 'types/masterData/geographies'

export const UpsertGeography = () => {
  const { t } = useTranslation()

  const { entryId } = useParams()
  const { state } = useLocation()
  const geographyState = state?.data as MayBeNull<Geography>

  const query = useFetchGeographyApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const geography = query.data || geographyState

  const form = useForm({
    defaultValues: geography ? convertToForm(geography) : defaultValues,
    validationSchema: useGeographyValidationScheme(),
  })

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        query={query as UseQueryReturnType}
        title={t(`common.${geography ? 'update' : 'create'}_entity`, {
          entity: t('common.master_data.entities.geography'),
        })}
        actionButtons={<ActionButtons form={form} geography={geography!} />}
        rightCards={geography && <EntityDetailsCards entity={geography} />}
      >
        <Flex direction="column" gap={24}>
          <ManageMasterDataLogoUpload name={geography?.name || ''} />
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormInputAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          {geography?.id && <GeographyMarketsByTypes geographyId={geography.id} />}
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
