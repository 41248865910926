import { WppSegmentedControlCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { SegmentedControlChangeEventDetail } from '@platform-ui-kit/components-library/dist/types/components/wpp-segmented-control/types'
import { WppSegmentedControl, WppSegmentedControlItem } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { SegmentOption } from 'components/form/formSegmentedControl/FormSegmentedControl'
import { MarketTypeLabel } from 'pages/markets/upsert/enums'
import { MarketType, MarketTypeDefaultValue } from 'types/masterData/markets'

interface Props
  extends Omit<ComponentPropsWithoutRef<typeof WppSegmentedControl>, 'onChange' | 'onWppChange' | 'value'> {}

export const FilterByType = ({ ...rest }: Props) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const filterType = (searchParams.get('type') as MarketType) || MarketTypeDefaultValue

  const marketTypeFilters = useMemo<SegmentOption[]>(
    () =>
      Object.values(MarketType).map(item => ({
        value: item,
        label: t(MarketTypeLabel[item], { count: 2 }),
      })),
    [t],
  )

  const handleChange = useCallback(
    (event: WppSegmentedControlCustomEvent<SegmentedControlChangeEventDetail>) => {
      const value = event.detail.value as MarketType
      if (value === MarketTypeDefaultValue) {
        const updatedSearchParams = new URLSearchParams(searchParams)
        updatedSearchParams.delete('type')
        setSearchParams(updatedSearchParams)
      } else {
        setSearchParams({ type: value })
      }
    },
    [searchParams, setSearchParams],
  )

  return (
    <WppSegmentedControl onWppChange={handleChange} value={filterType} size="m" {...rest}>
      {marketTypeFilters.map(({ value, label }) => (
        <WppSegmentedControlItem value={value} key={value}>
          {label}
        </WppSegmentedControlItem>
      ))}
    </WppSegmentedControl>
  )
}
